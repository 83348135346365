import algolia from "algoliasearch/lite";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import "@algolia/autocomplete-theme-classic";
import "./search-engine.widget.scss";

if (window.searchWidgetConfig === undefined) {
  window.searchWidgetConfig = {
    current_website: "pefc.org",
    website_hover:
      "This result comes from another website. If you click, you will be redirected to the website %s",
    search_engine_url: "http://pefc.test:8080/search",
    translations: {
      no_result: "No results for %s",
      placeholder: "Search term",
      suggestions: "Suggestions",
      quick_result: "Quick result",
      find_certified: {
        title: "Find certified",
        text: "If your search is for information on certificate holders, logo and label users, certified products, accredited certification bodies, and PEFC-endorsed national certification schemes. Please click on the link below.",
        link_label: "Search in pefc database",
      },
      types: {
        news: "News",
        projects: "Projects",
        events: "Events",
        "past-events": "Past events",
        documents: "Documents",
        stories: "Stories",
        articles: "Articles",
      },
    },
  };
}

const appId = "DLMPJIQBHV";
const apiKey = "8e444e19ffabe16b2db0631321dcc5dc";
const searchClient = algolia(appId, apiKey);
const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient,
  indexName: "PEFC - CMS - Query suggestions",
  transformSource({ source, onTapAhead }) {
    return {
      ...source,
      getItemUrl({ item }) {
        return window.searchWidgetConfig.search_engine_url + "?q=" + item.query;
      },
      templates: {
        ...source.templates,
        header({ item, createElement }) {
          return createElement("div", {
            dangerouslySetInnerHTML: {
              __html: `<div class="result-list-header suggestions"><h3>${window.searchWidgetConfig.translations.suggestions}</h3></div>`,
            },
          });
        },
        item({ item, createElement }) {
          return createElement("div", {
            dangerouslySetInnerHTML: {
              __html: `<div class="result-item suggestion">
                                        <p>
                                            <i class="fal fa-search"></i>                                  
                                            ${item._highlightResult.query.value
                                              .replace(
                                                "__aa-highlight__",
                                                "<em>",
                                              )
                                              .replace(
                                                "__/aa-highlight__",
                                                "</em>",
                                              )}
                                        </p>                                      
                                    </div>`,
            },
          });
        },
      },
    };
  },
  getSearchParams({ state }) {
    return { hitsPerPage: state.query ? 5 : 10 };
  },
});

window.searchAutocomplete = autocomplete({
  container: "#autocomplete",
  placeholder: window.searchWidgetConfig.translations.placeholder,
  autoFocus: true,
  plugins: [querySuggestionsPlugin],
  renderNoResults({ state }, root) {
    render(`No results for "${state.query}".`, root);
  },
  onSubmit(params) {
    let searchTerm = event.target[1].value;
    window.location.href =
      window.searchWidgetConfig.search_engine_url + "?q=" + searchTerm;
  },
  getSources({ query }) {
    return [
      {
        sourceId: "cms",
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: "PEFC - CMS",
                query,
                params: {
                  //facetFilters: ['website_name:'+window.searchWidgetConfig.current_website],
                  hitsPerPage: 5,
                  analytics: true,
                  analyticsTags: ["pefc_international"],
                },
              },
            ],
          });
        },
        onSelect({ item }) {
          // todo check if other website
          window.location.href = item.url;
        },
        getItemUrl({ item }) {
          return item.url;
        },
        templates: {
          header({ item, createElement }) {
            return createElement("div", {
              dangerouslySetInnerHTML: {
                __html: `<div class="result-list-header"><h3>${window.searchWidgetConfig.translations.quick_result}</h3></div>`,
              },
            });
          },
          item({ item, createElement }) {
            let same_website =
              item.website === window.searchWidgetConfig.current_website;

            if (
              window.searchWidgetConfig.translations.types.hasOwnProperty(
                item["type"],
              )
            ) {
              item["type_label"] =
                window.searchWidgetConfig.translations.types[item["type"]];
            }

            let html = `<div class="result-item">`;
            if (item.image != null) {
              html += `<img src="${item.image}" width="36" height="36" />`;
            } else {
              html += `<div style="height: 36px; width: 36px; display: inline; border-radius: 3px; float: left; margin-right: 10px; background-position: center center; background-size: cover; background-image: url(//www.pefc.org/assets/news-default.png)"></div>`;
            }
            html += `<div class="title">`;

            // if (item.image != null) {
            //     html +=     `<img src="${item.image}" style="height: 36px; width: 36px; />`;
            // } else {
            //     html +=     `<div style="height: 36px; width: 36px; display: inline-block; background-position: center center; background-size: cover; background-image: url("//www.pefc.org/assets/news-default.png")"></div>`;
            // }
            //
            // html = `        <div class="title">`

            // if (!same_website) {
            //     html += `       <span class="website">
            //                         <i class="fal fa-external-link"></i>${item.website}
            //                         <span>${window.searchWidgetConfig.website_hover} ${item.website}</span>
            //                     </span>`;
            // }
            html += `            <span class="type">${item.type_label}</span>
                                             <h5>${item.title}</h5>
                                           </div>
                                            <p class="summary">${item.summary}</p>
                                    </div>`;

            return createElement("div", {
              dangerouslySetInnerHTML: {
                __html: html,
              },
            });
          },
        },
      },
      {
        sourceId: "findCertified",
        getItems() {
          return [{ find_certified: true }];
        },
        onSelect({ item }) {
          let findCertifiedUrl = `https://pefc.org/find-certified?certificate_number=${query}&licence_number=${query}&name=${query}`;
          if (window.searchWidgetConfig.current_website === "pefc.org") {
            window.location.href = findCertifiedUrl;
          } else {
            window.open(findCertifiedUrl, "_blank");
          }
        },
        getItemUrl({ item }) {
          return `https://pefc.org/find-certified?certificate_number=${query}&licence_number=${query}&name=${query}`;
        },
        templates: {
          header({ item, createElement }) {
            return createElement("div", {
              dangerouslySetInnerHTML: {
                __html: `<div class="result-list-header">
                                            <h3>${window.searchWidgetConfig.translations.find_certified.title}</h3>
                                            <p>${window.searchWidgetConfig.translations.find_certified.text}</p>
                                        </div>`,
              },
            });
          },
          item({ item, createElement }) {
            return createElement("div", {
              dangerouslySetInnerHTML: {
                __html: `<div class="result-item find-certified"><h5 class="title">${window.searchWidgetConfig.translations.find_certified.link_label} <i class="far fa-chevron-right"></i></h5></div>`,
              },
            });
          },
        },
      },
    ];
  },
});

let sprintf = (str, ...argv) =>
  !argv.length
    ? str
    : sprintf((str = str.replace(sprintf.token || "$", argv.shift())), ...argv);
sprintf.token = "%s";
